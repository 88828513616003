// Import our external dependencies.
import { Workbox } from "workbox-window";
import { ready as i18nReady, translate } from "i18n"; //eslint-disable-line import/no-unresolved

// Create and Export a service worker instance.
const serviceWorker =
  "serviceWorker" in navigator ? makeServiceWorker() : false;
export default serviceWorker;

// -  - -  - -  - -  - -  - -  Private... - -  - -  - -  - -  - -  - -  - -  - |

// Function to create a service worker instance using Workbox.
/* eslint-disable no-alert, no-console */
function makeServiceWorker() {
  const wb = new Workbox("service-worker.js");

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener("waiting", async () => {
    // Load the language files before proceeding.
    await i18nReady;

    // Ask the user if we can restart now.
    const do_restart = window.confirm(translate("update_installed"));
    if (!do_restart) return;

    // Inject the updated application code. Will trigger a restart.
    wb.addEventListener("controlling", () => {
      wb.messageSW({ type: "RELOAD_ALL_WINDOWS" });
    });
    wb.messageSW({ type: "SKIP_WAITING" });
  });

  wb.register();

  // Add a global function for uninstallation
  window.uninstallIndigen = () => {
    return wb.messageSW({ type: "CLEAR_CACHE" }).then(() => {
      // Uninstall and alert them of the succeess.
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
        window.alert(translate("was_uninstalled"));
      });
    });
  };
}
/* eslint-enable no-alert, no-console */
